.app {
  overflow: hidden;
  position: relative;
}
.container {
  width: vh(1240px);
  margin: 0 auto;
  padding: 0 vh(0px);
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  &-min {
    width: vh(1020px);
  }

  @media (max-width: $Mobile) {
    width: 100%;
    padding: 0 vwm(20px);
  }
}

a {
  color: $TextColor;
}

b {
  font-weight: bold;
}

img {
  max-width: 100%;
}

svg {
  width: 100%;
  height: 100%;
}
button {
  cursor: pointer;
}
.color {
  &-green {
    color: $ColorGreen !important;
  }
}
.pagination {
  list-style: none;
  font-size: vh(16px);
  font-weight: 400;
  margin: vh(20px) 0 0;
  padding: vh(40px) 0 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  gap: vh(10px);
  @media screen and (max-width: $Mobile) {
    font-size: vwm(16px);
    padding: vwm(40px) 0 0;
    margin: vwm(20px) 0 0;
    gap: 0;
  }
  li {
    position: relative;
    padding: 0;
    width: vh(54px);
    height: vh(54px);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 2px solid #fff;
    &.active {
      border-radius: 9px;
      border: 2px solid #9bcdfd;
      a {
        color: #052994;
        position: relative;
        z-index: 2;
        font-weight: 700;
      }
      &::before {
        content: '';
        width: vh(44px);
        height: vh(44px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: $Color1;
        border-radius: 50%;
        @media screen and (max-width: $Mobile) {
          width: vwm(34px);
          height: vwm(34px);
        }
      }
    }
    &.disabled {
      display: none;
    }
    &.next,
    &.previous {
      font-size: 0;
      a {
        cursor: pointer;
        width: vh(32px);
        height: vh(32px);
        padding: 0 vh(24px);
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.88 27.3651L20.5733 18.6718C21.6 17.6451 21.6 15.9651 20.5733 14.9385L11.88 6.24512' stroke='%23A2AABE' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        @media screen and (max-width: $Mobile) {
          width: vwm(45px);
          height: vwm(45px);
          padding: 0 vwm(0px);
        }
      }
      @media screen and (max-width: $Mobile) {
        padding: 0;
      }
    }
    &.break-me {
      a {
        font-size: 0;
        background-color: #a2aabe;
        border-radius: vh(28px);
        height: vh(2px);
        width: vh(34px);
      }
      @media screen and (max-width: $Mobile) {
        padding: 0 vwm(3px);
        a {
          height: vwm(2px);
          width: vwm(34px);
          padding: 0;
        }
      }
    }
    &.previous {
      transform: rotate(180deg);
    }
    a {
      cursor: pointer;
      color: #1c2132;
    }
    @media screen and (max-width: $Mobile) {
      padding: 0 vwm(16px);
      width: vwm(45px);
      height: vwm(45px);
      box-sizing: border-box;
    }
  }
}
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.5);
  z-index: 3;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: vh(56px);
  left: 0;
  @media (max-width: $Mobile) {
    border-radius: vwm(56px);
    max-width: vwm(320px);
  }
}
.disabled .loader,
.loader.active,
.loader img {
  display: flex;
}
.form__action.disabled {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
  }
}
.anchor {
  position: absolute;
  top: vh(-30px);
}
.justify-content-center {
  justify-content: center;
}
.table {
  border: 1px solid #fed989;

  .tr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fed989;

    &:last-child {
      border: 0;
    }
  }

  .td {
    position: relative;
    border-right: 2px solid #fed989;
    flex: 1 0 auto;
    box-sizing: border-box;

    &:last-child {
      border: 0;
    }
  }
}
.tb__label {
  display: none;
  @media screen and (max-width: $Mobile) {
    display: inline-block;
    width: vwm(56px);
    margin-bottom: vwm(2px);
    font-weight: 900;
    line-height: 1.2;
    flex: vwm(56px) 0 0;
    flex-shrink: 0;
  }
}
.btn {
  font-weight: bold;
  font-size: vh(20px);
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 0;
  background: none;
  padding: 0;
  color: #fff;
  cursor: pointer;
  position: relative;
  min-width: vh(108px);
  &:not(.btn-disabled) {
    &:hover {
    }
  }

  @media (max-width: $Mobile) {
    font-size: vwm(18px);
    padding: 0 vwm(13px);
  }
  &-1 {
    color: $Color1;
    background-color: $Color2;
    border-radius: vh(9px);
    &:hover {
      color: $Color1;
      background: #0636c5;
    }
    @media (max-width: $Mobile) {
      border-radius: vwm(16px);
    }
  }
  &-2 {
    border: 2px solid $Color3;
    border-radius: vh(9px);
    color: $Color2;
    &:hover {
      background-color: #052994;
      border-color: #052994;
      color: $Color1;
    }
  }
  &-3 {
    border-radius: vh(9px);
    background-color: rgba(5, 41, 148, 0.48);
    margin-top: vh(10px);
  }
  &-sm {
    height: vh(42px);
    padding: 0 vh(30px);
    font-size: vh(16px);
    letter-spacing: vh(0.16px);
    @media (max-width: $Mobile) {
      height: vwm(51px);
      font-size: vwm(16px);
      padding: 0 vwm(30px);
      letter-spacing: vwm(0.24px);
    }
  }
  &-md {
    height: vh(55px);
    padding: 0 vh(36px);
    font-size: vh(16px);
    letter-spacing: vh(0.16px);
    @media (max-width: $Mobile) {
      font-size: vwm(16px);
      height: vwm(55px);
      padding: 0 vwm(30px);
      border-radius: vwm(9px);
    }
  }
  &-lg {
    height: vh(72px);
    padding: 0 vh(48px);
    font-size: vh(20px);
    letter-spacing: vh(0.2px);
    @media (max-width: $Mobile) {
      font-size: vwm(20px);
      height: vwm(72px);
      padding: 0 vwm(48px);
    }
  }
  &-xl {
    height: vh(96px);
    padding: 0 vh(64px);
    border-radius: vh(16px);
    font-size: vh(24px);
    letter-spacing: vh(0.24px);
    @media (max-width: $Mobile) {
      font-size: vwm(24px);
      height: vwm(96px);
      padding: 0 vwm(30px);
      letter-spacing: vwm(0.24px);
    }
  }
}
.slick-list,
.slick-track {
  transition: none;
}
h1,
h2,
.section-title {
  font-family: $font2;
  font-weight: normal;
}
h1 {
  font-size: vh(76px);
  line-height: 100%; /* 76px */
  letter-spacing: 2.28px;
  margin: vh(81px) 0 vh(40px);
  @media (max-width: $Mobile) {
    font-size: vwm(48px);
    margin: vwm(0px) 0 vwm(40px);
    text-align: center;
    letter-spacing: vwm(1.44px);
  }
}
h2,
.section-title {
  font-size: vh(60px);
  line-height: 120%;
  letter-spacing: 1.8px;
  color: $TextColor;
  margin: vh(0px) 0 vh(20px);
  @media (max-width: $Mobile) {
    font-size: vwm(40px);
    margin: vwm(0px) 0 vwm(20px);
    letter-spacing: vwm(1.2px);
  }
}
h3,
.section-title {
  font-size: vh(32px);
  line-height: 120%;
  letter-spacing: 1.8px;
  color: $TextColor;
  margin: vh(0px) 0 vh(20px);
  @media (max-width: $Mobile) {
    font-size: vwm(40px);
    margin: vwm(0px) 0 vwm(20px);
    letter-spacing: vwm(1.2px);
  }
}
.section-title {
  text-align: center;
}

.modal__box h3 {
	@media (max-width: $Mobile) {
		font-size: 8.66667vw;
	}
}

.cabinet .section-title {
  font-size: vh(64px);
}

p {
  font-size: vh(16px);
  color: $TextColor;
  line-height: 1.2;
  text-align: left;
}
ol {
  font-weight: 400;
  font-size: vh(34px);
  line-height: vh(42px);
  color: #f2bd30;
  span {
    color: #392a26;
  }
  li {
    &::marker {
      width: 40px;
    }
  }
}
.margin-0 {
  margin: 0;
}

.section__title {
  font-weight: 500;
  font-size: vh(25px);
  line-height: vh(30px);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;

  @media (max-width: $Mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.openMenu {
  overflow-y: hidden;
}
.btn-close-menu {
  position: fixed;
  left: vwm(265px);
  top: 0;
  width: vwm(114px);
  height: 100vh;
  // transform: translateX(vwm(261px));
  z-index: 56;
  background-color: #015b27;
  &-top {
    background-color: #3dae2b;
    text-align: center;
    padding: vwm(8px) 0 0 vwm(2px);
    display: flex;
    .logo {
      width: vwm(112px);
      height: vwm(62px);
      display: flex;
    }
  }
  @media (max-width: $Mobile) {
  }
}

.d-f-wrap {
  flex-wrap: wrap;
}

.d {
  &-gap {
    &-30 {
      gap: vh(30px);
    }
  }
  &-text-center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &-text-left {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-text-right {
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (max-width: $Mobile) {
  .m {
    &-text-center {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    &-text-left {
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &-text-right {
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;
    }
    &-gap {
      &-30 {
        gap: wph(17px);
      }
    }
  }
}
.text-center {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.text-left {
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
}

.text-right {
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
}
.d-transform {
  text-transform: uppercase;
}
.d-f-justify-space-between {
  justify-content: space-between;
}
.d-f-justify-center {
  justify-content: center;
}

.d-f-justify-stretch {
  justify-content: stretch;
}

.d-f-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.d-aling-start {
  align-items: flex-start !important;
}

.d-aling-end {
  align-items: flex-end !important;
}

.d-aling-center {
  align-items: center !important;
}

.d-aling-stretch {
  align-items: stretch !important;
}

.d-flex {
  display: flex !important;
}

.d-f-column {
  flex-direction: column;
}

.d-f-row {
  flex-direction: row;
}
.d-hidden {
  display: none !important;
}

.d-m-hidden {
  display: none !important;
}

.m-hidden {
  display: flex !important;
}
.hidden {
  display: none !important;
}
.d-col {
  box-sizing: border-box;

  &-6 {
    width: 50%;
    display: inline-block;
  }

  &-6 {
    width: 50%;
  }

  &-12 {
    width: 100%;
    box-sizing: border-box;
  }

  &-2 {
    width: 20%;
  }

  &-9 {
    width: 75%;
  }

  &-8 {
    width: 66.66666667%;
    box-sizing: border-box;
  }

  &-3 {
    width: 25%;
  }

  &-4 {
    width: 33.3333%;
  }
  &-5 {
    width: 42%;
  }
}

.d-order {
  &-1 {
    order: 1;
  }

  &-2 {
    order: 2;
  }

  &-3 {
    order: 4;
  }

  &-5 {
    order: 6;
  }
}

.popover {
  position: relative;
  &-content {
    background: rgba(144, 173, 154, 0.4);
    backdrop-filter: blur(4px);
    border-radius: vh(10px);
    padding: vh(15px) vh(30px);
    font-weight: 600;
    font-size: vh(20px);
    line-height: vh(28px);
    color: #ffffff;
    display: none;
    position: absolute;
    bottom: calc(100% + 10px);
    box-sizing: border-box;
    left: 80%;
    min-width: vh(340px);
    max-width: 100%;
  }
  &:hover {
    .popover-content {
      display: flex;
    }
  }
}
.table__wrap {
  padding-right: vh(0px);
  position: relative;
}
.tb__col {
  box-sizing: border-box;
  @media (min-width: $DesctopeMin) {
    &:nth-child(1) {
      width: vh(292px);
    }
    &:nth-child(2) {
      width: vh(295px);
    }
    &:nth-child(3) {
      width: vh(295px);
    }
    &:nth-child(4) {
      width: vh(270px);
    }
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span.ico-flag {
    @media screen and (max-width: $Mobile) {
      margin-right: vwm(9px);
    }
  }
}

.tb__th {
  font-size: vh(16px);
  font-weight: 700;
  line-height: 115%;
  color: #a2aabe;
  cursor: pointer;
  letter-spacing: vh(0.16px);
  @media screen and (max-width: $Mobile) {
    font-size: vwm(16px);
    line-height: vwm(15px);
    letter-spacing: 0.02em;
    font-weight: 700;
  }
  &.active {
    -webkit-text-stroke: initial;
    -webkit-text-fill-color: $Color1;
  }
}
.table__wrap {
  @media screen and (max-width: $Mobile) {
    padding: vwm(0px) vwm(0px) 0 vwm(0px);
  }
}
.table__head {
  padding: vh(0px) 0 vh(40px) 0;
  margin-bottom: vh(20px);
  border-bottom: 1px solid #e8e8e8;
  @media screen and (max-width: $Mobile) {
    padding: vwm(0px) vwm(0) vwm(11px) vwm(0);
  }
  .tb__col {
    box-sizing: border-box;
    &:nth-of-type(4) {
      // text-align: right;
    }
  }
}

.table__body {
  color: #1c2132;
  @media screen and (max-width: $Mobile) {
    padding: vwm(0px) vwm(0px) 0 vwm(0px);
  }
  .tb__col {
    font-size: vh(20px);
    line-height: 115%;
    font-weight: 400;
    padding-top: vh(20px);
    padding-bottom: vh(20px);
    display: flex;
    align-items: center;
    position: relative;
    &.col__check {
      align-items: center;
      display: flex;
      span {
        display: none;
        padding-right: vh(20px);
      }
    }
    img {
      width: vh(89px);
      height: vh(89px);
      object-fit: cover;
      @media screen and (max-width: $Mobile) {
        width: vwm(54px);
        height: vwm(54px);
      }
    }
    &.number {
      font-size: vh(70px);
      display: flex;
      color: $ColorGreen;
      &-1 {
        color: $Color6;
      }
      &-2 {
        color: #bebebe;
      }
      &-3 {
        color: #e19d58;
      }
      span {
        display: inline-block;
        width: vh(98px);
        text-align: center;
        @media screen and (max-width: $Mobile) {
          width: vwm(55px);
        }
      }
      @media screen and (max-width: $Mobile) {
        font-size: vwm(40px);
      }
    }
    &.ball {
      &-1 {
        .ball-coll {
          &::after {
            content: '';
            display: inline-block;
            width: vh(76px);
            height: vh(76px);
            background-size: cover;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M75.2092 37.9587C75.1718 17.4229 58.494 0.805638 37.9583 0.842971C17.4225 0.880304 0.805226 17.5581 0.842559 38.0939C0.879892 58.6296 17.5577 75.2469 38.0935 75.2096C58.6292 75.1722 75.2465 58.4944 75.2092 37.9587Z' fill='%23F1BC41'/%3E%3Cpath d='M56.2347 66.9665C55.4689 66.9665 54.7882 66.7112 54.1075 66.2858L38.0259 54.4586L21.8593 66.2858C21.2637 66.7112 20.4979 66.9665 19.7321 66.9665C18.6259 66.9665 17.5198 66.3708 16.8391 65.4349C16.1584 64.4989 15.9882 63.3928 16.3286 62.2866L22.54 43.3121L6.37332 31.57C5.097 30.634 4.58648 29.1025 5.097 27.5709C5.60753 26.0393 6.88384 25.1033 8.50051 25.1033L28.4961 25.1884L34.6224 6.1288C35.1329 4.68231 36.4943 3.66125 38.0259 3.66125C39.5575 3.66125 40.9189 4.68231 41.4294 6.1288L47.5557 25.1884L67.5513 25.1033C69.168 25.1033 70.4443 26.0393 70.9548 27.5709C71.4654 29.1025 70.9548 30.634 69.6785 31.57L53.5119 43.3121L59.7233 62.2866C60.0636 63.3928 59.8934 64.584 59.2127 65.4349C58.447 66.4559 57.4259 66.9665 56.2347 66.9665ZM9.09612 28.9323L24.9224 40.4191C26.1987 41.3551 26.7093 42.9717 26.1987 44.4182L20.1575 62.9673L35.9838 51.3954C36.5794 50.97 37.3452 50.7147 38.111 50.7147C38.8768 50.7147 39.5575 50.97 40.2382 51.3954L55.8943 62.9673L49.768 44.3331C49.2575 42.8867 49.768 41.27 51.0443 40.334L66.9557 28.9323L47.3856 29.0174C45.854 29.0174 44.4926 27.9963 43.9821 26.5498L38.0259 7.91564L32.0698 26.5498C31.5593 27.9963 30.1979 29.0174 28.6663 29.0174L9.09612 28.9323Z' fill='%23F7D78D'/%3E%3C/svg%3E%0A");
            @media screen and (max-width: $Mobile) {
              width: vwm(43px);
              height: vwm(43px);
            }
          }
        }
      }
      &-2 {
        .ball-coll {
          &::after {
            content: '';
            display: inline-block;
            width: vh(76px);
            height: vh(76px);
            background-size: cover;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M75.2095 38.0261C75.2095 17.4903 58.5619 0.842784 38.0261 0.842785C17.4903 0.842786 0.842771 17.4903 0.842772 38.0261C0.842773 58.5619 17.4903 75.2095 38.0261 75.2095C58.5619 75.2095 75.2095 58.5619 75.2095 38.0261Z' fill='%23BEBEBE'/%3E%3Cpath d='M56.2356 66.96C55.4699 66.96 54.7892 66.7047 54.1085 66.2793L38.0269 54.4521L21.8602 66.2793C21.2646 66.7047 20.4988 66.96 19.7331 66.96C18.6269 66.96 17.5208 66.3644 16.8401 65.4284C16.1594 64.4924 15.9892 63.3863 16.3295 62.2802L22.5409 43.3056L6.37429 31.5635C5.09798 30.6276 4.58745 29.096 5.09798 27.5644C5.6085 26.0328 6.88482 25.0969 8.50148 25.0969L28.4971 25.182L34.6234 6.12233C35.1339 4.67584 36.4953 3.65479 38.0269 3.65479C39.5585 3.65479 40.9199 4.67584 41.4304 6.12233L47.5567 25.182L67.5523 25.0969C69.169 25.0969 70.4453 26.0328 70.9558 27.5644C71.4663 29.096 70.9558 30.6276 69.6795 31.5635L53.5128 43.3056L59.7242 62.2802C60.0646 63.3863 59.8944 64.5775 59.2137 65.4284C58.4479 66.4495 57.4269 66.96 56.2356 66.96ZM9.0971 28.9258L24.9234 40.4126C26.1997 41.3486 26.7102 42.9653 26.1997 44.4118L20.1585 62.9609L35.9848 51.389C36.5804 50.9635 37.3462 50.7082 38.112 50.7082C38.8778 50.7082 39.5585 50.9635 40.2392 51.389L55.8953 62.9609L49.769 44.3267C49.2585 42.8802 49.769 41.2635 51.0453 40.3276L66.9567 28.9258L47.3865 29.0109C45.855 29.0109 44.4936 27.9899 43.983 26.5434L38.0269 7.90917L32.0708 26.5434C31.5602 27.9899 30.1988 29.0109 28.6673 29.0109L9.0971 28.9258Z' fill='%23D8D8D8'/%3E%3C/svg%3E%0A");
            @media screen and (max-width: $Mobile) {
              width: vwm(43px);
              height: vwm(43px);
            }
          }
        }
      }
      &-3 {
        .ball-coll {
          &::after {
            content: '';
            display: inline-block;
            width: vh(76px);
            height: vh(76px);
            background-size: cover;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg width='75' height='77' viewBox='0 0 75 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M74.5387 38.9836C74.5387 18.289 57.9997 1.30218 37.5979 1.04248C17.1961 0.782782 0.657224 17.3486 0.657225 38.0432C0.657226 58.7378 17.1961 75.7247 37.5979 75.9844C57.9997 76.2441 74.5387 59.6783 74.5387 38.9836Z' fill='%23E19D58'/%3E%3Cpath d='M55.6398 67.7481C54.8809 67.7481 54.2062 67.4903 53.5316 67.0605L37.5932 55.1132L21.5706 67.0605C20.9802 67.4903 20.2213 67.7481 19.4623 67.7481C18.366 67.7481 17.2697 67.1464 16.5951 66.201C15.9204 65.2555 15.7518 64.1381 16.0891 63.0208L22.2452 43.8536L6.22251 31.9922C4.95756 31.0468 4.45159 29.4996 4.95756 27.9525C5.46354 26.4054 6.72849 25.4599 8.33076 25.4599L28.1483 25.5459L34.22 6.29271C34.726 4.83153 36.0753 3.80011 37.5932 3.80011C39.1112 3.80011 40.4604 4.83153 40.9664 6.29271L47.0382 25.5459L66.8557 25.4599C68.458 25.4599 69.7229 26.4054 70.2289 27.9525C70.7349 29.4996 70.2289 31.0468 68.9639 31.9922L52.9413 43.8536L59.0973 63.0208C59.4347 64.1381 59.266 65.3415 58.5914 66.201C57.8324 67.2324 56.8204 67.7481 55.6398 67.7481ZM8.92107 29.3277L24.6064 40.9312C25.8714 41.8767 26.3774 43.5098 25.8714 44.9709L19.884 63.7084L35.5693 52.019C36.1596 51.5892 36.9186 51.3314 37.6776 51.3314C38.4365 51.3314 39.1112 51.5892 39.7858 52.019L55.3025 63.7084L49.2308 44.885C48.7248 43.4238 49.2308 41.7907 50.4957 40.8453L66.2654 29.3277L46.8695 29.4137C45.3516 29.4137 44.0023 28.3823 43.4963 26.9211L37.5932 8.09769L31.6901 26.9211C31.1842 28.3823 29.8349 29.4137 28.3169 29.4137L8.92107 29.3277Z' fill='%23EEC49B'/%3E%3C/svg%3E%0A");
            @media screen and (max-width: $Mobile) {
              width: vwm(43px);
              height: vwm(43px);
            }
          }
        }
      }
    }
    .punkt {
      color: $Color6;
      margin-right: vh(110px);
      @media screen and (max-width: $Mobile) {
        margin-right: vwm(29px);
      }
    }
    @media screen and (max-width: $Mobile) {
      font-weight: 400;
      line-height: 115%;
      font-size: vwm(20px);
      padding: 0;
      display: flex;
      align-items: center;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding: vwm(10px) 0;
      &:first-child {
        font-weight: 700;
      }
      &.col__date {
        font-size: vwm(12px);
        line-height: 1;
      }
      &.col__check {
        font-size: vwm(16px);
        margin-bottom: vwm(15px);
      }
      &.col__win {
        font-size: vwm(20px);
        font-weight: 600;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        justify-content: flex-end;
        .popover {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        span.popover-content {
          font-size: vwm(12px);
          font-style: italic;
          font-weight: 500;
          line-height: 1;
          display: flex;
          position: relative;
          max-width: auto;
          left: initial;
          bottom: initial;
          position: relative;
          margin-right: vwm(32px);
          background: none;
          color: $Color2;
          letter-spacing: 0.01em;
          padding: 0;
        }
      }
    }

    &.tb__col-empty {
      @media screen and (max-width: $Mobile) {
        width: 100%;
      }
    }
  }

  .table__rows {
    height: 100%;
  }
  .table__row {
    padding: 0;
    border-bottom: 1px solid #fff;
    &.isCurrent {
      background: rgba(61, 174, 43, 0.5);
      border: vh(5px) solid #f2bd30;
      @media screen and (max-width: $Mobile) {
        border: vwm(3px) solid #f2bd30;
      }
    }
    // min-height: 100%;
    &:hover {
      .tb__col {
        // font-weight: 600;
      }
    }
    &-empty {
      border: 0;
      text-align: center;
      text-transform: uppercase;
      color: #f2bd30;
      height: 100%;
      align-items: center;
      justify-content: center;

      .tb__col-empty {
        width: vh(608);
        max-width: 100%;
        font-weight: 700;
        font-size: vh(50px);
        line-height: vh(58px);
        @media screen and (max-width: $Mobile) {
          font-size: vwm(21px);
          line-height: vwm(23px);
          width: 100%;
        }
      }
    }
    @media screen and (max-width: $Mobile) {
      flex-direction: row;
      padding: vwm(10px) 0 vwm(10px) vwm(0px);
      position: relative;
      flex-wrap: wrap;
    }
  }
}

.table__scroll {
  // height: vh(523px);
  @media screen and (max-width: $Mobile) {
    // height: vwm(370px);
  }
}

.scrollbar-box,
.table__scroll > div {
  // padding-right: 20px;
}
.scrollbar-vertical {
  height: 100%;
  right: 0;
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
  width: 100%;
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: absolute;
  height: 100%;
  width: 100%;
  @media screen and (max-width: $Mobile) {
    // display: none;
  }
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 2;
  @media screen and (max-width: $Mobile) {
    margin: vwm(0px);
  }
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.05) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 3px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 5px;
  right: 5px;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 0px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
  border-radius: vh(25px);
  @media screen and (max-width: $Mobile) {
    // background: #ffe3ca;
  }
}
.rcs-custom-scroll .rcs-inner-container {
  max-height: vh(490px);
  @media (max-width: $Mobile) {
    // max-height: vwm(538px);
    max-height: 81vh;
  }
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: 100%;
  margin-top: 0px;
  background-color: #052994;
  border-radius: vh(10px);
  cursor: pointer;
}

.table__wrap {
  .rcs-custom-scroll .rcs-inner-handle {
  }
}

.tab__pane {
  display: none;

  &.active {
    display: block;
    margin-top: vh(50px);
  }
}

.table__wrap-codes {
  .table__scroll {
    height: 232px;
  }

  .tb__col {
    &:nth-child(1) {
      width: 11%;
      min-width: 11%;
      padding-left: 10px;
    }

    &:nth-child(2) {
      width: 38%;
      min-width: 38%;
    }

    &:nth-child(3) {
      width: 51%;
      min-width: 51%;
    }
    @media (max-width: $Mobile) {
      padding: 0;
    }
  }

  .table__body {
    .table__row:last-child {
      border-bottom: 2px solid #e3ded2;
    }
  }
}

.table__wrap-codes {
  margin-bottom: 70px;
}
.codes-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 2;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &:after {
    content: '';
    background: url('../img/loader.svg') no-repeat center;
    background-size: contain;
    width: 64px;
    height: 64px;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px;
    position: absolute;
  }
}

// @media (max-width: $Planshet) {
//   .p-flex {
//     display: flex !important;
//   }

//   .p-f-column {
//     flex-direction: column;
//   }

//   .p-f-row {
//     flex-direction: row;
//   }

//   .box-container {
//     padding: vwp(40px) vwp(57px) 0;
//   }

//   .d-hidden {
//     display: block !important;
//   }

//   p {
//     font-size: vwp(16px);
//   }

//   .p-col {
//     box-sizing: border-box;

//     &-6 {
//       width: 50%;
//       display: inline-block;
//     }

//     &-6 {
//       width: 50%;
//       flex: 0 0 50%;
//     }

//     &-12 {
//       width: 100%;
//       box-sizing: border-box;
//     }

//     &-2 {
//       width: 20%;
//     }

//     &-9 {
//       width: 75%;
//     }

//     &-8 {
//       width: 66.66666667%;
//       box-sizing: border-box;
//     }

//     &-3 {
//       width: 25%;
//     }

//     &-4 {
//       width: 33.3333%;
//     }
//   }

//   .p-order {
//     &-1 {
//       order: 1;
//     }

//     &-2 {
//       order: 2;
//     }

//     &-3 {
//       order: 4;
//     }

//     &-5 {
//       order: 6;
//     }
//   }
// }

@media (max-width: $Mobile) {
  .d-hidden {
    display: block !important;
  }
  .m-hidden {
    display: none !important;
  }
  .container {
    width: 100%;
  }

  footer {
    width: 100%;
  }

  .m-flex {
    display: flex !important;
  }

  .m-f-column {
    flex-direction: column;
  }

  .m-f-row {
    flex-direction: row;
  }

  .m-text-center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .m-text-left {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .m-text-right {
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .box-container {
    padding: vwm(20px) vwm(37px) 0 vwm(27);
  }

  p {
    font-size: vwm(16px);
    text-align: center;
  }

  .m-col {
    box-sizing: border-box;

    &-auto {
      width: auto;
    }

    &-6 {
      width: 50%;
      display: inline-block;
    }

    &-6 {
      width: 50%;
    }

    &-12 {
      width: 100%;
      box-sizing: border-box;
    }

    &-2 {
      width: 20%;
    }

    &-9 {
      width: 75%;
    }

    &-8 {
      width: 66.66666667%;
      box-sizing: border-box;
    }

    &-3 {
      width: 25%;
    }

    &-4 {
      width: 33.3333%;
    }
  }

  .m-order {
    &-1 {
      order: 1;
    }

    &-2 {
      order: 2;
    }

    &-3 {
      order: 4;
    }

    &-5 {
      order: 6;
    }
  }

  .m-text-left {
    text-align: left !important;
    justify-content: flex-start !important;
  }
  .m-text-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .m-f-justify-center {
    justify-content: center;
  }

  .m-f-justify-space-between {
    justify-content: space-between;
  }

  .m-f-justify-stretch {
    justify-content: stretch;
  }
}
