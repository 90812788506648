.error__message {
  position: absolute;
  top: calc(100% + 0.3vh);
  left: 0;
  width: 100%;
  font-size: vh(14px);
  padding: vw(0px);
  z-index: 5;
  color: #ff0000;
  @media screen and (max-width: $Mobile) {
    font-size: vwm(12px);
    padding: 0;
    left: 0;
    top: calc(100% + 4px);
  }
}
apm-validation-error {
  color: #fff;
  line-height: 1.2;
}
input[type='checkbox'],
input[type='radio'] {
  display: none;
}
input[type='checkbox'] + label,
input[type='radio'] + label {
  cursor: pointer;
  font-size: vw(16);
  color: rgb(255, 255, 255);
  line-height: 1;
  text-align: left;
  position: relative;
  a {
    font-size: vw(16);
    color: rgb(255, 255, 255);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
input[type='checkbox'] + label::before,
input[type='radio'] + label::before {
  content: '';
  display: inline-block;
  height: vw(22);
  width: vw(22);
  margin: 0 vw(17) 0 0;
  background-repeat: no-repeat;
  border: vw(2) solid #fff;
  border-radius: vw(5);
}
input[type='checkbox'] + label::before {
}
input[type='radio'] + label::before {
}
input[type='checkbox']:checked + label::after {
  content: '';
  display: inline-block;
  height: vw(17);
  width: vw(23);
  background: #ccc;
  position: absolute;
  left: 0;
  top: vw(1);
  /*background: url('../images/lead/check.svg');*/
  background-size: 100% 100%;
}
input[type='radio']:checked + label::before {
}
input[type='checkbox']:disabled + label::before {
}
input[type='checkbox']:checked:disabled + label::before {
}
input[type='radio']:disabled + label::before {
}

.form__field.has-error input[type='text'], .form__field.has-error input[type='password'], .form__field.has-error input[type='email'] {
	border: 1px solid #ff0000;
}

.search__field.has-error input[type='text'] {
	border: 1px solid #ff0000;
}

.search__field .error__message {
	display: none !important;
}
